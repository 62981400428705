<template>
	<div id="sharinglogin">
		<div class="loginbox">
			<div class="titlebox">				
				<span>{{shop_name||''}}</span>
				<span v-if="guard_name=='periphery_admin_user'">{{type==1?'站点':'商家'}}</span>
				<span>登录后台</span>			
			</div>
			
			<div class="inputbox" style="margin-bottom: 40px;">
				<div class="label">登录账号</div>
				<el-input type="text" style="input" v-model="user_name" placeholder="请输入账号"></el-input>
			</div>
			
			<div class="inputbox">
				<div class="label">登录密码</div>
				<el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
			</div>
			
			<el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
		</div>
	</div>
</template>

<script>
import { eltips,getUrlKey } from '@/util/util.js';
import {resetRouter} from '@/router/index.js'
import store from '@/store/index.js'
export default {
	name: 'sharinglogin',
	data() {
		return {
			shop_name:'',
			user_name:'',//用户名
			password:'',//密码
			type:'', //1、站点 2、商家
			guard_name:'',//应用名称
			shop_id:'',
		};
	},
	created() {
		if(getUrlKey('type')){
			this.type = getUrlKey('type')
		}
		
		if(getUrlKey('name')){
			this.shop_name = getUrlKey('name')
		}
		
		if(getUrlKey('shop_id')){
			this.shop_id = getUrlKey('shop_id')
		}
		
		if(getUrlKey('guard_name')){
			this.guard_name = getUrlKey('guard_name')
		}
	},
	methods:{
		//登录
		login(){
			if(!this.user_name){
				eltips('请输入账号','error')
				return
			}
			if(!this.password){
				eltips('请输入密码','error')
				return
			}
			
			let data = {
				shop_id:this.shop_id,
				account:this.user_name,
				password:this.password
			}
			if(this.guard_name){
				data.guard_name = this.guard_name
			}
			if(this.type){
				data.type = this.type
			}
			console.log(this.shop_id,'this.shop_id')
			sessionStorage.setItem('sharshopId',this.shop_id)
			
			this.axios.post('/api/infinite/sellerAdmin/login',data).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('token',res.data.token)
					sessionStorage.setItem('guardName','infinite_seller_admin')
					sessionStorage.removeItem('role_id')
					sessionStorage.removeItem('pluginroute')
					sessionStorage.removeItem('pluginid')
					this.getuserinfo()
					eltips(res.msg,'success')
				}else{
					eltips(res.msg,'error')
				}
			})
		},
		//获取个人信息
		getuserinfo(){
			this.axios.get('/api/me',{params:{
				guard_name:this.guard_name
			}}).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('userinfo',JSON.stringify(res.data))
					// if(res.data.user.unitid==2){
					// 	sessionStorage.setItem('role_id', res.data.roles[0].id);
					// }
					this.getmeuns()
				}
			})
		},
		//获取权限菜单
		getmeuns(){
			this.axios.get('/api/get_menu').then(res=>{
				console.log(res)
				resetRouter()
				this.$store.commit('routestatus', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				sessionStorage.setItem('routes',JSON.stringify(res.data))
				this.$router.replace({path: '/index'})
			})
		},
	}
};
</script>


<style>
	#sharinglogin .el-input__inner{
		border: none !important;
	}
	
	#sharinglogin .el-button{
		padding: 18px 20px !important;
		font-size: 16px;
		font-weight: bold;
	}
</style>
<style scoped lang="less">
#sharinglogin{
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: url(../../assets/images/login_bg.png);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4px 4px 2px rgba(0,0,0,.1);
	.loginbox{
		width: 380px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 30px;
	}
	.titlebox{
		font-size: 20px;
		color: #1E92FF;
		margin-bottom: 40px;
		font-weight: bold;
	}
	.inputbox{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #DEDEDE;
		padding-bottom: 6px;
		.label{
			width: 80px;
		}
		.input{
			
		}
	}
	.loginbtn{
		margin-top: 160px;
		width: 100%;
	}
}
</style>
